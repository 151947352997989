/*
 * Import ES6 Modules 
 */

import "@lottiefiles/lottie-player";
import lottie from 'lottie-web';

var url = "https://func-form-send-email.azurewebsites.net/api/HttpTriggerFormMail?code=QS8cFL9hLI2BpS6YP0OXRUQbGc3kYKM4x-vF577shsBLAzFuMO4f5g==";



window.onload = function () {


    /*
     * bindCharacterCountLimit
     * Will show the number of chars left that a user can enter
     * while auto showing as the user types
     * @param inputElement - currently and Id of the input to watch
     * @param counterDisplay - display of characters
     * @param charLimit - limit to enter
     */
    function bindCharacterCountLimit(inputElement, counterDisplay, charLimit) {
        var pdmessageTextBox = document.getElementById(inputElement);
        var pdcharCountText = document.getElementById(counterDisplay);
        var nCharMax = charLimit;

        var displayNCharLeft = function (event) {

            var nCharLeft = nCharMax - pdmessageTextBox.value.length;

            if (nCharLeft >= 0) {
                pdcharCountText.innerHTML = nCharLeft;
            } else {
                pdcharCountText.innerHTML = 0;
                pdmessageTextBox.value = pdmessageTextBox.value.substr(0, nCharMax);
            }
        };
        displayNCharLeft();

        pdmessageTextBox.addEventListener('keypress', displayNCharLeft);
        pdmessageTextBox.addEventListener('keyup', displayNCharLeft);
    }


    bindCharacterCountLimit('message', 'char-count', 180);

    /*
     * ProcessingSignUp
     * When the form is submitted and the AJAX Request is in progress there can be a noticable
     * delay with the response, so to assist with this a new class is added to the registration
     * to indicate to the user that something is happening.
     * @@param processing boolean to indicate form init request and completion.
     */
    function processingInterest(processing = false) {
        const classToToggle = 'is-validating';
        const divReg = document.querySelector('.registration');
        if (!divReg.classList.contains(classToToggle) && processing) {
            divReg.classList.add(classToToggle);
        } else if (!processing && divReg.classList.contains(classToToggle)) {
            divReg.classList.remove(classToToggle);
        }
    }

    /*
     * successRegisteringInterest
     * When completed will load the lottie animation to show the tick
     */
    function successRegisteringInterest() {

        const divReg = document.querySelector('.registration');
        divReg.classList.add('is-complete');

        let tickContainer = document.getElementById('lottie_thankyou');

        lottie.loadAnimation({
            container: tickContainer,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: 'https://assets10.lottiefiles.com/packages/lf20_uywti8w5.json'
        });

    }



    document.getElementById("frmRegisterInterest").onsubmit = function onSubmit(event) {

        event.preventDefault();


        const contactForm = document.getElementById("frmRegisterInterest");
       
        var payload = {};

        contactForm.querySelectorAll("input, textarea").forEach(field => {
            payload[field.name] = field.value;
        });

       
        processingInterest(true);      

        fetch(url, {
          method: 'post',
          body: JSON.stringify(payload),
         }).then(
            
             response => {
                 processingInterest(false);
                 if (!response.ok) {
                     const divReg = document.querySelector('.registration');
                     divReg.classList.add('is-error');
                     return;
                 } else {
                     successRegisteringInterest();
                 }
         });
        
        
    }
}

